import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls } from "@react-three/drei";

function Model2(props) {
  const { scene } = useGLTF("/Test2.glb");
  return <primitive object={scene} {...props} />
}

function App() {
  return (
    <div className="videos2">
      <div>
        <Canvas dpr={[1, 2]} shadows camera={{ fov: 45 }} style={{ "position": "relative", width: "100%", height: "100vh" }}>
          <color attach="background" args={["#101010"]} />
          <PresentationControls speed={1.5} global zoom={.5} polar={[-0.1, Math.PI / 4]}>
            <Stage environment={null}>
              <Model2 scale={0.01} />
            </Stage>
          </PresentationControls>
        </Canvas>
      </div>

    </div>

  );
}

export default App;
